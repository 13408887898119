import React from "react"
import { Container,Typography, Grid } from "@material-ui/core"
import { Facebook, LinkedIn, Phone, Email, LocationOn, PhoneAndroid } from "@material-ui/icons"
import styles from "./footer.module.css"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Logo from "../../assets/Logo.png"

const ContactDetails = () => {
	return(
		<>
			<Grid container>
				<Grid item xs={1}>
					<LocationOn className={styles.contactIcon} color="primary"/>
				</Grid>
				<Grid item xs={10}>
					<div style={{width: '70%'}}>
						<Typography className={styles.contactText} variant="body2">
							No 22, Yafa building, Model school road, Thousand lights, Chennai - 600006	
						</Typography>		
					</div>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={1}>
					<Phone className={styles.contactIcon} color="primary"/>
				</Grid>
				<Grid item xs={10}>
					<div>
						<Typography className={styles.contactText} variant="body2">Phone: <a href="tel:04428294255">+91-44-2829-4255</a></Typography>		
					</div>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={1}>
					<PhoneAndroid className={styles.contactIcon} color="primary" />
				</Grid>
				<Grid item xs={10}>
					<div>
						<Typography className={styles.contactText} variant="body2">Mobile: <a href="tel:919940034506">+91-9791-079621</a></Typography>
					</div>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={1}>
					<Email className={styles.contactIcon} color="primary"/>
				</Grid>
				<Grid item xs={10}>
					<div>
						<Typography className={styles.contactText} variant="body2">Email: selvarajuarch@gmail.com</Typography>
					</div>
				</Grid>
			</Grid>
		</>
	)
}

const SocialMedia = () => {
	return(
		<div>
			<OutboundLink styles={{textAlign: 'right'}} name="footer_facebook_link" label="footer_faccebook_link" href="https://www.facebook.com/Selvaraju-Architect-109021920776294/?modal=composer&ref=admin_hovercard" rel="noopener noreferrer" target="_blank">
				<Facebook style={{marginRight: "18px"}} color="primary" className={styles.socialMediaIcons} />
			</OutboundLink>    
			<OutboundLink styles={{textAlign: 'right'}} name="footer_linkedin_link" label="footer_linkedin_link" href="https://www.linkedin.com/company/selvaraju-architects/" rel="noopener noreferrer" target="_blank">
				<LinkedIn className={styles.socialMediaIcons} color="primary" />
			</OutboundLink>
		</div>
	)
}
const Footer = () => {
	return(
		<>
			<div className={styles.footerContainer + ' ' + styles.deskFooter}>
				<Container>
					<footer className={styles.footer}>
						<Grid container>
							<Grid item sm={6}>
								<div style={{textAlign: 'left'}}>
									<img alt="footer_logo" src={Logo} style={{width: '17em'}}/>
								</div>
							</Grid>
							<Grid item sm={2}>
								<div style={{textAlign: 'left', margin: '30px 0 0 20px'}}>
									<p style={{marginBottom: '5px'}}><a className={styles.footerLink} href="/">Our work</a></p>
									<p style={{marginBottom: '5px'}}><a className={styles.footerLink} href="/about">About us</a></p>
									<p style={{marginBottom: '5px'}}><a className={styles.footerLink} href="/contact">Contact us</a></p>
								</div>
							</Grid>
							<Grid item sm={4}>
								<ContactDetails />						
							</Grid>
						</Grid>
						<Grid container style={{marginTop: '10px'}}>
							<Grid item sm={10}>
								<p style={{color: "white", margin: '20px 0px 0px 10px', textAlign: 'left'}}>
									Powered by <OutboundLink name="footer_yrt_link" label="footer_yrt_link" className={styles.footerLink} href="http://www.yogirtech.com/" rel="noopener noreferrer" target="_blank">YogiR Tech Pvt Ltd.</OutboundLink>
								</p>   
							</Grid>
							<Grid item sm={2}>
								<SocialMedia />
							</Grid>
						</Grid>
					</footer>
				</Container>
			</div>

			<div className={styles.footerContainer + ' ' + styles.mobFooter}>
				<Container>
					<footer className={styles.footer}>
						<ContactDetails />
						<SocialMedia />
						<p style={{color: "white", marginBottom: '0px'}}>
							Powered by <OutboundLink name="footer_yrt_link" label="footer_yrt_link" className={styles.footerLink} href="http://www.yogirtech.com/" rel="noopener noreferrer" target="_blank">YogiR Tech Pvt Ltd.</OutboundLink>
						</p>          
					</footer>
				</Container>
			</div>     
		</>  
	)
}

export default Footer