import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from "../header/header"
import Footer from "../footer/footer"
import "./layout.css"
import "../../stylesheets/fonts.css"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00adbb"
    },
    secondary: {
      main: "#ffffff"
    },
  }
});

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header pageTitle={pageTitle} siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
          
          <Footer />
        </div>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
