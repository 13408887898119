import React from "react"
import styles from "./landing.module.css"
import "../../stylesheets/fonts.css"
import AboutVideo from "../../assets/AboutLanding.mp4"
import ContactVideo from "../../assets/ContactLanding.mp4"
import AboutBgImg from "../../assets/about_bg.jpg"
import ContactBgImg from "../../assets/contact_bg.jpg"

const About = () => {
  return(
    <>
			<div className={styles.companyQuote}>
				<h1 className="landingText">"Ambient design for a wonderful living experience"</h1>
			</div>
    </>
  )
}

const Contact = () => {
  return(
    <>
			<div className={styles.companyQuote}>
				<h1 className="landingText">Each new situation requires a new architecture</h1>
			</div>
    </>
  )
}

const Landing = ({type}) => {

  const landing = {
    backgroundImage: null
  }
  let LandingContent = null;

  if(type === 'About') {
    landing.backgroundImage = `url(${AboutBgImg})`;
    LandingContent = (<About />)
  } else if(type === 'Contact us') {
    landing.backgroundImage = `url(${ContactBgImg})`;
    LandingContent = (<Contact />)
  } else {
    landing.backgroundImage = null;
  }

  if(type)
  return (
    <>
      <div className={styles.landingContainer}>
        <>
          {LandingContent}
        </>
        <div className={styles.videoContainer}>
          <div className={styles.overlay}></div>
          <video style={{...landing}} className={styles.backgroundVideo} playsInline autoPlay loop muted>
            { type === 'About' ? (<source src={AboutVideo} type="video/mp4" />) : null }
            { type === 'Contact us' ? (<source src={ContactVideo} type="video/mp4" />) : null }
          </video>
        </div>
      </div>
    </>
  )
}

export default Landing
