import React, { useState } from "react"
import {AppBar, Toolbar, Box, List, ListItem, IconButton} from "@material-ui/core"
import SelvarajuLogo from '../../assets/Logo.png'
import { Menu, LinkedIn, Close } from '@material-ui/icons'; 
import styles from "./header.module.css"
import { Link } from "gatsby"
import { Facebook } from "@material-ui/icons"
import { Phone } from "@material-ui/icons";
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Header = (props) => {

  let [menuOpn, toggleMenuOpn] = useState(false)

  const HandleAddUnderline = title => {
    return props.pageTitle === title ? styles.active : null
  }  

  let val =  menuOpn ? ('flex') : ('none');

  const mobileNav = {
    position: 'fixed',
    top: '7vh',
    width: '100%',
    height: '93vh',
    backgroundColor: '#000000ee',
    zIndex: '2000',
    justifyContent: 'center', 
    alignItems: 'center',
    display: val
  }

  return (
  <>
    <AppBar position="fixed" style={{boxShadow: "none", backgroundColor: "black"}}>
      <Toolbar className={styles.headerContainer}>
        <div>
          <Link className={styles.companyName} to="/">
            <img src={SelvarajuLogo} alt="Logo" />
          </Link>
        </div>
        <Box className={styles.desktopPart} display={{xs: 'none', sm: 'none', md: 'block'}}>
          <div className={styles.menu}>
            <Link to="/"><span className={HandleAddUnderline("Home")}>Work</span></Link>
            <Link to="/about"><span className={HandleAddUnderline("About")}>About</span></Link>
            <Link to="/contact"><span className={HandleAddUnderline("Contact")}>Contact</span></Link>
          </div>
        </Box>
        <Box className={styles.desktopPart} display={{xs: 'none', sm: 'none', md: 'block'}}>
          <div className={styles.socialMedia}>
            <OutboundLink name="header_facebook_link" label="header_facebook_link" href="https://www.facebook.com/Selvaraju-Architect-109021920776294/?modal=composer&ref=admin_hovercard" rel="noopener noreferrer" target="_blank"><Facebook /></OutboundLink>
            <OutboundLink name="header_linkedin_link" label="header_linkedin_link" href="https://www.linkedin.com/company/selvaraju-architects/" rel="noopener noreferrer" target="_blank"><LinkedIn /></OutboundLink> 
          </div>
        </Box>
        <Box className={styles.mobilePart} display={{ sm: 'block', md: 'none', lg: 'none', xl: 'none'}}>
          <IconButton color="primary" href="tel:919940034506" className="callBtn"><Phone /></IconButton>
          <IconButton color="primary" className="callBtn" onClick={() => {toggleMenuOpn(!menuOpn)}}>
            {menuOpn ? (<Close />) : (<Menu />) }
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
    <Box style={mobileNav}>
      <List style={{}}>
        <ListItem button component="a" href="/" className={styles.mobileLink}>WORK</ListItem>
        <ListItem button component="a" href="/about" className={styles.mobileLink}>ABOUT</ListItem>
        <ListItem button component="a" href="/contact" className={styles.mobileLink}>CONTACT</ListItem>
      </List>
    </Box>
  </>
)}


export default Header